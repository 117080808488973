export enum notificationTitleTypeEnum {
	INVITATION = 'Invitation',
	NEW_OFFER = 'New Offer',
	NEW_OFFER_STATUS = 'New Offer Status',
	OFFER_ACCEPTED = 'Offer Accepted',
	OFFER_REJECTED = 'Offer Rejected',
	DELIVERY_IN_PROGRESS = 'Delivery in progress',
	DELIVERY_UPDATE = 'Delivery new update',
	DELIVERY_FINISHED = 'Delivery finished',
	COUNTEROFFER_ACCEPTED = 'Counteroffer accepted',
	INTERESTED = 'New Interest',
	PRIVATE_FREIGHT_REQUEST = 'Private Freight Request',
}
export enum notificationTypeEnum {
	COUNTER_OFFER = 'COUNTER_OFFER',
	OFFER = 'OFFER',
	DELIVERY = 'DELIVERY',
	INTEREST = 'INTEREST',
	PRIVATE_FREIGHT_REQUEST = 'PRIVATE_FREIGHT_REQUEST',
}
