// @ts-nocheck
import { useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { CONFIG } from '@/config';
import { notificationTitleTypeEnum, notificationTypeEnum } from '@/enums/NotificationEnum';

export type ShipmentType = {
	_id: string;
	name: string;
};

export type SinceLastLoginDataResponse = {
	updatedAt: string;
	_id: string;
	freightOwner: {
		name: string;
		_id: string;
		isBluecheck?: boolean;
	};
	shipment: ShipmentType;
	type: notificationTypeEnum;
	notification: notificationTitleTypeEnum;
	relationId: string;
};

type SinceLastLoginResponse = {
	data?: SinceLastLoginDataResponse[];
	message: string;
};

export const useSinceLastLogin = () => {
	const { getAccessTokenSilently } = useAuth0();
	const [response, setResponse] = useState<SinceLastLoginResponse | undefined>();
	const [loading, setLoading] = useState(true);
	const hasFetched = useRef(false);

	useEffect(() => {
		if (hasFetched.current) return;
		hasFetched.current = true;
		const fetchLastestUpdates = async () => {
			const { access_token } = await getAccessTokenSilently({ detailedResponse: true });
			const response = await fetch(`${CONFIG.API_V2}/since-last-login`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${access_token}`,
				},
			}).then((res) => res.json());
			setResponse(response);
			setLoading(false);
		};
		fetchLastestUpdates();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasFetched]);
	return { response, loading };
};
