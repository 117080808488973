import { Locale, formatDistance, format as formatDateFns } from 'date-fns';
import { enGB, nb } from 'date-fns/locale';
import i18n from '@/i18n';

const { t } = i18n;

class DateUtils {
	static format = (date: Date, formatString: string) => {
		// Convert the date to UTC equivalent in local time
		const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
		return formatDateFns(utcDate, formatString);
	};

	static formatUserTimeZone = (date: Date, formatString: string) => {
		return formatDateFns(date, formatString);
	};
	static convertToUTC = (date: Date): any => {
		const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
		return utcDate;
	};
	static dateDaysAgo(timeSince: number) {
		const getLocale = (): Locale => {
			const currentLocale = i18n.language;
			if (currentLocale === 'en') {
				return enGB;
			}
			return nb;
		};
		return formatDistance(new Date().getTime() + timeSince, new Date().getTime(), {
			locale: getLocale(),
		});
	}
	static expiresIn(date: string): { expired: boolean; expiredInText: string } {
		// Calculate the difference and return it in human format
		const expired = new Date(date).getTime() < new Date().getTime();
		return {
			expiredInText: formatDistance(new Date(date).getTime(), new Date().getTime(), {
				addSuffix: true,
			}),
			expired,
		};
	}
	static UTCArrayToISODate(dateUTCString: string[]): (Date | null)[] {
		if (!dateUTCString || dateUTCString?.some((date) => !date)) {
			return [null, null];
		}
		return dateUTCString?.map?.((date: string) => new Date(date));
	}

	static dateArrayToUTCString(dateArray: Date[] | string[]) {
		return dateArray.map((date) => this.convertToUTC(new Date(date)));
	}

	static humanReadableDate(date: string) {
		return this.format(new Date(date), 'dd/MM/yyyy');
	}

	static humanReadableDateWithTime(date: string) {
		return this.format(new Date(date), 'dd/MM/yyyy HH:mm');
	}

	static getWeekNumber(date: Date): number {
		const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
		const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
		return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
	}

	static UTCArrayToReadableString(dateUTCString: string[], forceRange = false) {
		if (!dateUTCString || !dateUTCString[0]) return null;
		if (dateUTCString && Array.isArray(dateUTCString) && dateUTCString.length > 1) {
			const formattedDates = dateUTCString.map((date) => {
				return this.format(new Date(date), 'dd/MM/yyyy');
			});
			if (!forceRange && formattedDates.every((val) => val === formattedDates[0])) {
				// This is a measure of last resort - there are so many places this could
				// have bubbled up from, I don't know. Just prevent that.
				if (formattedDates[0] === '01/01/1970') {
					return 'As soon as possible';
				}
				return formattedDates[0];
			} else {
				return formattedDates.join(` ${t('common.to').toLowerCase()} `);
			}
		}
		return this.format(new Date(dateUTCString[0]), 'dd/MM/yyyy');
	}

	static UTCArrayToWeekString(dateUTCString: string[], forceRange = false) {
		if (!dateUTCString || !dateUTCString[0]) return null;
		if (dateUTCString && Array.isArray(dateUTCString) && dateUTCString.length > 1) {
			// This is a measure of last resort - there are so many places this could
			// have bubbled up from, I don't know. Just prevent that.
			if (dateUTCString[0] === '1970-01-01T00:00:00.000Z') {
				return '';
			}
			const formattedDates = dateUTCString.map((date) => DateUtils.getWeekNumber(new Date(date)));
			if (!forceRange && formattedDates.every((val) => val === formattedDates[0])) {
				return `Week ${formattedDates[0]}`;
			} else {
				return `Weeks ${formattedDates.join(`, `)}`;
			}
		}
		return `Week ${DateUtils.getWeekNumber(new Date(dateUTCString[0]))}`;
	}

	static formatOrASAP(dateString: string) {
		try {
			if (dateString === '1970-01-01T00:00:00.000Z') {
				return 'As soon as possible';
			} else {
				return this.format(new Date(dateString), 'dd/MM/yyyy');
			}
		} catch (e) {
			return '';
		}
	}
}

export default DateUtils;
