import React from 'react';

import { CONFIG, ROUTES } from '@/config';
import Loader from '@components/loader';
import { IndexLayout } from '@components/layouts';
import { useEffect } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';

import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import useGetCurrentUser from '@/hooks/useGetCurrentUser';

function AppRouter() {
	const { currentUser } = useGetCurrentUser();
	// const Routes = useRoutes(getRoutes(currentUser));
	const location = useLocation();
	useEffect(() => {
		ReactGA.initialize([
			{
				trackingId: CONFIG.GOOGLE_ANALYTICS_ID,
			},
		]);
		ReactGA.send({
			hitType: 'pageview',
			page: location.pathname,
			title: document.title,
		});
	}, [location]);

	useEffect(() => {
		if (!currentUser?.isAdmin && CONFIG.APP_ENV === 'production') {
			hotjar.initialize(3818493, 6);
			if (hotjar.initialized()) {
				hotjar.identify('USER_ID', { userProperty: currentUser?.email });
			}
		}
	}, [currentUser]);

	return (
		<React.Suspense fallback={<Loader />}>
			<IndexLayout>{useRoutes(ROUTES)}</IndexLayout>
		</React.Suspense>
	);
}

export default AppRouter;
