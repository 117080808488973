// eslint-disable-next-line
// @ts-nocheck
import { ReactNode } from 'react';
import {
	TbMap2,
	TbPackageExport,
	TbGavel,
	TbShip,
	TbSquare1Filled,
	TbSquare2Filled,
	TbFileInvoice,
	TbHelp,
} from 'react-icons/tb';

import * as PATH from '@config/path';
import { Arrow } from '@/assets/icons/arrow';
import useGetCurrentUser from '@/hooks/useGetCurrentUser';
import { useTranslation } from 'react-i18next';

const roles = {
	so: 'ship-operator',
	ff: 'freight-forwarder',
	co: 'cargo-owner',
	admin: 'admin',
};

interface Item {
	title: string;
	access: string[];
	children?: Item[];
	icon?: ReactNode;
	path?: string;
}

export interface Section {
	title: string;
	items: Item[];
}
export function useSidebar() {
	const { t } = useTranslation();
	const { currentUser } = useGetCurrentUser();

	const userAccessLevel = currentUser?.operation;
	const isAdmin = currentUser?.isAdmin;
	const getSections = (): Section[] => [
		{
			title: 'General',
			items: [
				{
					// Backoffice Dashboard must be for admin only
					title: 'Admin Menu',
					path: `/${PATH.ADMIN.INDEX}/${PATH.ADMIN.USERS.INDEX}`,
					icon: <TbSquare1Filled size={'24px'} />,
					access: [roles?.admin],
					children: [
						{
							title: 'Admin Users',
							path: `/${PATH.ADMIN.INDEX}/${PATH.ADMIN.USERS.INDEX}`,
							icon: <TbSquare1Filled size={'24px'} />,
							access: [roles?.admin],
						},
						{
							title: 'Admin Freight requests',
							path: `/${PATH.ADMIN.INDEX}/${PATH.ADMIN.FREIGHT_REQUESTS.INDEX}`,
							icon: <TbSquare2Filled size={'24px'} />,
							access: [roles?.admin],
						},
						{
							title: 'Admin Deliveries',
							path: `/${PATH.ADMIN.INDEX}/${PATH.ADMIN.DELIVERIES.INDEX}`,
							icon: <TbSquare2Filled size={'24px'} />,
							access: [roles?.admin],
						},
						{
							title: 'Admin Stats',
							path: `/${PATH.ADMIN.INDEX}/${PATH.ADMIN.STATS.INDEX}`,
							icon: <TbPackageExport size={'24px'} />,
							access: [roles?.admin],
						},
						{
							title: 'Admin Reports',
							path: `/${PATH.ADMIN.INDEX}/${PATH.ADMIN.OKR.INDEX}`,
							icon: <TbPackageExport size={'24px'} />,
							access: [roles?.admin],
						},
						{
							title: 'Admin OPS',
							path: `/${PATH.ADMIN.INDEX}/${PATH.ADMIN.MATCH_MAKING.INDEX}/match-making-todo/inactive`,
							icon: <TbPackageExport size={'24px'} />,
							access: [roles?.admin],
						},
						{
							title: 'Admin Circulate',
							path: `/${PATH.ADMIN.INDEX}/${PATH.ADMIN.CIRCULATION.INDEX}/${PATH.ADMIN.CIRCULATION.CIRCULATE}`,
							icon: <TbPackageExport size={'24px'} />,
							access: [roles?.admin],
						},
						{
							title: 'Admin Positions Map',
							path: `/app/positions/map`,
							icon: <TbPackageExport size={'24px'} />,
							access: [roles?.admin],
						},
						{
							title: 'Admin Heatmap',
							path: `/app/heatmap/map`,
							icon: <TbPackageExport size={'24px'} />,
							access: [roles?.admin],
						},
						{
							title: 'Admin Vessels',
							path: `/app/vessels`,
							icon: <TbPackageExport size={'24px'} />,
							access: [roles?.admin],
						},
						{
							title: 'Admin Rolodex',
							path: `/app/vessels/fleet`,
							icon: <TbPackageExport size={'24px'} />,
							access: [roles?.admin],
						},
					],
				},
				// TODO: Admin Viewclicks don't have an index, only a detail
				// {
				// 	title: 'Admin Viewclick',
				// 	path: `/${PATH.ADMIN.INDEX}/${PATH.ADMIN.VIEWCLICKS.DE}`,
				// 	icon: <TbSquare2Filled size={'24px'} />,
				// 	access: [roles?.admin],
				// },

				// Power users
				{
					title: 'Freight Requests',
					path: `/${PATH.APP}/${PATH.CARRIERS.INDEX}`,
					icon: <TbMap2 size={'24px'} />,
					access: [roles?.so],
				},

				// Map
				{
					title: t('dashboardSidebar.map'),
					path: `/${PATH.APP}/${PATH.MAP}`,
					icon: <TbMap2 size={'24px'} />,
					access: [roles?.so, roles?.ff],
				},

				{
					title: t('dashboardSidebar.freights'),
					path: `/${PATH.APP}/${PATH.FREIGHT.INDEX}/active`,
					icon: <TbPackageExport size={'24px'} />,
					access: [roles?.ff],
					// children: [
					// 	{
					// 		title: t('dashboardSidebar.requestFreight'),
					// 		path: `/${PATH.APP}/${PATH.FREIGHT.INDEX}/${PATH.FREIGHT.REQUEST}`,
					// 		icon: <MdAdd size={'24px'} />,
					// 		access: [roles?.ff],
					// 	},
					// ],
				},
				{
					title: t('dashboardSidebar.offers'),
					path: `/${PATH.APP}/${PATH.OFFERS.INDEX}/all`,
					icon: <TbGavel size={'24px'} />,
					access: [roles?.so],
				},
				{
					title: t('dashboardSidebar.deliveriesMenu'),
					path: `/${PATH.APP}/${PATH.DELIVERIES.INDEX}/ongoing`,
					icon: <TbShip size={'24px'} />,
					access: [roles?.so, roles?.ff],
				},
				{
					title: 'Fleet and Capacity',
					path: `/${PATH.APP}/${PATH.AVAILABLECAPACITY.INDEX}`,
					icon: <TbShip size={'24px'} />,
					access: [roles?.so],
				},
				{
					title: 'Invoices',
					path: `/${PATH.APP}/${PATH.INVOICES.INDEX}`,
					icon: <TbFileInvoice size={'24px'} />,
					access: [roles?.so],
				},
				{
					title: t('dashboardSidebar.help'),
					path: `https://www.keelspot.com/faq`,
					icon: <TbHelp size={'24px'} />,
					access: [roles?.so],
				},
				{
					title: t('dashboardSidebar.help'),
					path: `https://www.keelspot.com/faq`,
					icon: <TbHelp size={'24px'} />,
					access: [roles?.ff],
				},
			],
		},
		{
			title: 'Actions',
			items: [
				{
					title: 'Switch Operations',
					icon: <Arrow />,
					access: [...Object.values(roles)],
				},
			],
		},
	];
	//Filter dashboard menu by access level
	const sections = getSections().map((section: Section) => {
		const items = filterByAccess(section.items);

		function filterByAccess(item: Item[]) {
			return item.filter((item: Item) => {
				if (isAdmin && item.access.includes(roles?.admin)) return true;
				return item.access.includes(userAccessLevel!);
			});
		}
		return {
			title: section.title,
			items: items,
		};
	});

	return {
		sections,
	};
}
