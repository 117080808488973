/* eslint-disable jsx-a11y/click-events-have-key-events */
import { INotification } from '@/interfaces/notification/INotification';
import { IconButton, Typography } from '@mui/material';
import { FC } from 'react';
import { NotificationPop } from './Styled';
import { IoMdClose } from 'react-icons/io';
import { notificationTitleTypeEnum, notificationTypeEnum } from '@/enums/NotificationEnum';
import DateUtils from '@/utils/DateUtils';

interface NotificationItemProps {
	notification: INotification;
	changeStatusNotification: (notification: INotification) => void;
	deleteNotification: (notification: INotification) => void;
}

export const FormatNotificationText = ({ notification }: { notification: INotification }) => {
	const message =
		notification.notification === notificationTitleTypeEnum.OFFER_ACCEPTED
			? 'accepted'
			: notification.notification === notificationTitleTypeEnum.OFFER_REJECTED
			? 'rejected'
			: null;

	const formatOfferMessage = (text: notificationTitleTypeEnum): JSX.Element => {
		if (text === notificationTitleTypeEnum.PRIVATE_FREIGHT_REQUEST) {
			return (
				<Typography variant='body2' className='text-ellipsis overflow-hidden'>
					<b>{notification.freightOwner?.name}</b> sent you have a <b>private freight request</b>.
				</Typography>
			);
		}
		if (text === notificationTitleTypeEnum.NEW_OFFER) {
			return (
				<Typography variant='body2' className='text-ellipsis overflow-hidden'>
					<b>{notification.freightOwner?.name}</b> has a new offer to{' '}
					<b>{notification.shipment?.name}</b>.
				</Typography>
			);
		} else {
			return (
				<Typography variant='body2' className='text-ellipsis overflow-hidden'>
					<b>{notification.freightOwner?.name}</b> has {message} your offer for{' '}
					<b>{notification.shipment?.name}</b>.
				</Typography>
			);
		}
	};

	const formatDeliveryMessage = (text: notificationTitleTypeEnum): JSX.Element => {
		if (text === notificationTitleTypeEnum.DELIVERY_IN_PROGRESS) {
			return (
				<Typography variant='body2' className='text-ellipsis overflow-hidden'>
					<b>{notification.shipment?.name}</b> is currently under delivery.
				</Typography>
			);
		} else if (text === notificationTitleTypeEnum.DELIVERY_UPDATE) {
			return (
				<Typography variant='body2' className='text-ellipsis overflow-hidden'>
					<b>{notification.shipment?.name}</b> has a new delivery update.
				</Typography>
			);
		} else if (text === notificationTitleTypeEnum.DELIVERY_FINISHED) {
			return (
				<Typography variant='body2' className='text-ellipsis overflow-hidden'>
					<b>{notification.shipment?.name}</b> has been delivered.
				</Typography>
			);
		} else if (text === notificationTitleTypeEnum.COUNTEROFFER_ACCEPTED) {
			return (
				<Typography variant='body2' className='text-ellipsis overflow-hidden'>
					The counteroffer on <b>{notification.shipment?.name}</b> has been accepted! Visit the
					deliveries page to put in the required details.
				</Typography>
			);
		} else if (text === notificationTitleTypeEnum.INTERESTED) {
			return (
				<Typography variant='body2' className='text-ellipsis overflow-hidden'>
					<b>{notification.freightOwner?.name}</b> has expressed interest on your freight request!
				</Typography>
			);
		}

		// TODO: add counter offer text into this mess
		return (
			<Typography variant='body2' className='text-ellipsis overflow-hidden'>
				There is a <b>new counter offer</b> on your negotiation.
			</Typography>
		);
	};

	return (
		<div>
			{notification.type === notificationTypeEnum.OFFER ||
			notification.type === notificationTypeEnum.PRIVATE_FREIGHT_REQUEST
				? formatOfferMessage(notification.notification)
				: formatDeliveryMessage(notification.notification)}

			<Typography variant='caption' color='textSecondary'>
				{DateUtils.format(new Date(notification.createdAt), "dd/MM/yyyy 'at' hh:mma")}
			</Typography>
		</div>
	);
};

const NotificationItem: FC<NotificationItemProps> = ({
	notification,
	changeStatusNotification,
	deleteNotification,
}) => {
	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			className='flex flex-row w-full justify-between'
			onClick={() => {
				changeStatusNotification(notification);
			}}
		>
			<div className='basis-1/6 self-center justify-center flex pr-3'>
				{notification.active ? <NotificationPop /> : null}
			</div>
			<div className='basis-2/3 text-ellipsis overflow-hidden whitespace-normal'>
				<FormatNotificationText notification={notification} />
			</div>
			<IconButton
				className='basis-1/5 bg-transparent'
				aria-label='settings'
				size='small'
				onClick={() => {
					deleteNotification(notification);
				}}
			>
				<IoMdClose />
			</IconButton>
		</div>
	);
};

export default NotificationItem;
