import { useRef } from 'react';
import { Tooltip } from '@mui/material';
import { TbSwitchHorizontal } from 'react-icons/tb';
import useGetCurrentUser from '@/hooks/useGetCurrentUser';
import { useDisclosure } from '@/hooks/useDisclosure';
import { OperationEnum } from '@/enums/OperationEnum';
import Button from '@components/Button';
import { SwitchOperationsModal } from '@components/sideMenu/SwitchOperations';

export const SwitchOperationButton = ({ className }: any) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { currentUser } = useGetCurrentUser();
	const anchorRef = useRef<HTMLDivElement | null>(null);

	if (!currentUser || currentUser.companyPrimaryOperationSlug === OperationEnum.FREIGHT_FORWARDER)
		return null;

	const operatingAsCargoOwner = currentUser.operation === OperationEnum.FREIGHT_FORWARDER;

	return (
		<div className={`${className} flex items-center gap-1`}>
			<Tooltip
				title={`Change role to ${
					operatingAsCargoOwner ? 'make offers on request' : 'post freight requests'
				}`}
				arrow
			>
				<div ref={anchorRef}>
					<Button
						className='px-3'
						variant='contained'
						color='header'
						onClick={onOpen}
						startIcon={<TbSwitchHorizontal size={24} />}
					>
						<div className='flex flex-col items-center'>
							<span className='font-normal text-xs text-keelspot-primary-superLight'>Operating as</span>
							<span>{operatingAsCargoOwner ? 'Shipper' : 'Carrier'}</span>
						</div>
					</Button>
				</div>
			</Tooltip>
			<SwitchOperationsModal isOpen={isOpen} onClose={onClose} />
		</div>
	);
};
