import ChatInbox from '@/components/Chat/ChatInbox';
import { Popover } from '@mui/material';
import { FC } from 'react';
interface LanguagePopoverProps {
	anchorEl: null | Element;
	onClose?: () => void;
	open: boolean;
}
export const MessagePopover: FC<LanguagePopoverProps> = (props) => {
	const { anchorEl, onClose, open } = props;
	return (
		<Popover
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: -10,
				horizontal: 'right',
			}}
			onClose={onClose}
			open={open}
			PaperProps={{ sx: { overflow: 'hidden' } }}
			transitionDuration={0}
		>
			<div>
				<ChatInbox prop={onClose} />
			</div>
		</Popover>
	);
};
