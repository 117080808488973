import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import KeelspotLogo from '@/assets/KeelspotLogo';

const Header = () => (
	<div className='w-full py-4 px-4 flex justify-center items-center h-[72px] bg-keelspot-headerBackground shadow-lg'>
		<KeelspotLogo color={'#ffffff'} />
	</div>
);

const PublicLayoutBase = () => {
	return (
		<div>
			<Header />
			<main className='max-w-[1376px] py-16 px-4 mx-auto'>
				<Outlet />
			</main>
		</div>
	);
};

export const PublicLayout = memo(PublicLayoutBase);

const SignupLayoutBase = () => {
	return (
		<div>
			<main className='w-full h-full'>
				<Outlet />
			</main>
		</div>
	);
};

export const SignupLayout = memo(SignupLayoutBase);
