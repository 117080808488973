// @ts-nocheck
import { useEffect, useRef, useState } from 'react';
import Badge from '@mui/material/Badge';
import {
	IconButton,
	Tooltip,
	Modal,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Select,
	MenuItem,
} from '@mui/material';
import { MdShoppingCart } from 'react-icons/md';
import { IconWrapper } from './Styled';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useTranslation } from 'react-i18next';
import { useGetAllShipments } from '@/api/shipment';
import Button from '@/components/Button';
import { CONFIG } from '@/config';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';

export const CartIcon = ({ currentComponent }: { currentComponent: HTMLDivElement | null }) => {
	const { t } = useTranslation();
	const anchorRef = useRef<HTMLButtonElement | null>(null);
	const { isOpen, onToggle, onClose } = useDisclosure();
	const [cartCount, setCartCount] = useState(0);
	const [cartItems, setCartItems] = useState([]);
	const [selectedFreightRequest, setSelectedFreightRequest] = useState('');
	const { getAccessTokenSilently } = useAuth0();
	const [isCirculating, setIsCirculating] = useState(false);

	const showBadge = (notificationNumber: number) => notificationNumber > 0;

	const updateCartCount = () => {
		const cart = JSON.parse(localStorage.getItem('kscart')) || [];
		setCartCount(cart.length);
		setCartItems(cart);
	};

	useEffect(() => {
		updateCartCount();
		const handleCartUpdate = () => updateCartCount();
		window.addEventListener('cartUpdated', handleCartUpdate);
		const interval = setInterval(() => {
			const currentCart = JSON.parse(localStorage.getItem('kscart')) || [];
			if (currentCart.length !== cartCount) {
				updateCartCount();
			}
		}, 1000);
		return () => {
			window.removeEventListener('cartUpdated', handleCartUpdate);
			clearInterval(interval);
		};
	}, [cartCount]);

	const { data: requests, isLoading } = useGetAllShipments({
		status: 'active',
		owner: '',
		query: '',
	});

	const handleFreightRequestChange = (event) => {
		setSelectedFreightRequest(event.target.value);
	};

	const removeFromCart = (id: string) => {
		const updatedCart = cartItems.filter((item) => item.id !== id);
		localStorage.setItem('kscart', JSON.stringify(updatedCart));
		updateCartCount();
	};

	const clearCart = () => {
		localStorage.removeItem('kscart');
		updateCartCount();
	};

	const circulate = async () => {
		if (!selectedFreightRequest) {
			toast.error('Please select a Freight Request');
			return;
		}

		setIsCirculating(true);

		try {
			const { access_token } = await getAccessTokenSilently({ detailedResponse: true });

			const vesselData = cartItems.map((item) => ({
				name: item?.name,
				emails: item?.ownership?.map((owner) => owner.email).filter(Boolean),
			}));

			const response = await fetch(`${CONFIG.API_V2}/circulation/admin-circulate`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${access_token}`,
				},
				body: JSON.stringify({
					vessels: vesselData,
					shipmentId: selectedFreightRequest,
				}),
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const result = await response.json();

			if (result.ok) {
				toast.success('Emails circulated successfully!');
				clearCart(); // Clear the cart after successful circulation
				onClose(); // Close the modal
			} else {
				toast.error('Failed to circulate emails');
			}
		} catch (error) {
			console.error('Error:', error);
			toast.error(`Failed to circulate emails. ${error.message}`);
		} finally {
			setIsCirculating(false);
		}
	};

	return (
		<>
			<Tooltip title={'Cart'} arrow>
				<IconButton onClick={onToggle} ref={anchorRef} sx={{ p: 0, borderRadius: 100 }}>
					<IconWrapper>
						<Badge
							badgeContent={showBadge(cartCount) ? <span id='notifier-badge'>{cartCount}</span> : null}
						>
							<MdShoppingCart size={24} />
						</Badge>
					</IconWrapper>
				</IconButton>
			</Tooltip>
			<Modal
				open={isOpen}
				onClose={onClose}
				aria-labelledby='cart-modal-title'
				aria-describedby='cart-modal-description'
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 1200,
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
						maxHeight: '80vh',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<TableContainer component={Paper} sx={{ flexGrow: 1, overflowY: 'auto', mb: 2 }}>
						<Table sx={{ minWidth: 650 }} aria-label='cart table' stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell>Vessel Name</TableCell>
									<TableCell>Owners</TableCell>
									<TableCell>Action</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{cartItems.map((item) => (
									<TableRow key={item.id} sx={{ '&:nth-of-type(odd)': { backgroundColor: 'action.hover' } }}>
										<TableCell component='th' scope='row'>
											{item.name}
										</TableCell>
										<TableCell>
											{item.ownership?.map((owner, index) => (
												<div key={owner._id}>
													<strong>{owner.name}</strong>
													<br />
													{owner.email && `${owner.email}`}
												</div>
											))}
										</TableCell>
										<TableCell>
											<Button
												type='button'
												color='error'
												variant='outlined'
												size='small'
												onClick={() => removeFromCart(item.id)}
											>
												Remove
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box sx={{ mt: 2 }}>
						<Select
							value={selectedFreightRequest}
							onChange={handleFreightRequestChange}
							displayEmpty
							fullWidth
						>
							<MenuItem value='' disabled>
								Select Freight Request
							</MenuItem>
							{requests &&
								requests.map((req, index) => (
									<MenuItem key={req._id} value={req._id}>
										<div className='flex flex-row w-full justify-between gap-2'>
											<div className='flex'>
												<span className=''>{req.name} </span>
												<span className='text-keelspot-text-secondary text-italic pl-4'>
													POL {req.loadingPort?.portName}
												</span>
											</div>
											<div className='flex'>
												<span className='text-keelspot-text-secondary'>
													{req.freights
														.flatMap((freight) => freight.cargos.map((cargo) => cargo.product))
														.join(', ')}
												</span>
											</div>
										</div>
									</MenuItem>
								))}
						</Select>
					</Box>
					<Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
						<div className='flex flex-row gap-1'>
							<Button type='button' color='error' variant='outlined' onClick={clearCart}>
								Clear Cart
							</Button>
							<Button variant='outlined' onClick={onClose}>
								Cancel
							</Button>
						</div>
						<Button
							type='button'
							color='success'
							onClick={circulate}
							disabled={isCirculating || !selectedFreightRequest}
						>
							{isCirculating ? 'Circulating...' : 'Circulate Emails'}
						</Button>
					</Box>
				</Box>
			</Modal>
		</>
	);
};
