import { gql } from 'graphql-request';

export const LIST_OWN_VESSEL = gql`
	query {
		listAllVesselsByOwner {
			message
			data {
				_id
				callSign
				imoNumber
				registerNumber
				name
				imo: imoNumber
				email
				status
				dimension {
					overallLength
					depth
					length
					width
					grossTonnage
					netTonnage
					vesselCapacity
					dwt
				}
				specification {
					vesselType
					constructionDate
					country
					jnr
					material
					propulsion
				}
				additionalInfo {
					has_excavator
					has_ramp
					has_side_door
					has_crane
					crane_total_tonnage
					vesselType
					cranes
					excavator
					refeerRooms
					refeerSlots
					forklift
					vesselCapacity
					fuelConsumption
					details
					sideDoor
					equippedWithCrane
					numberOfCranes
					craneDetail
					opex {
						currency
						amount
					}
				}
				attachments {
					_id
					mimetype
					path
					originalName
				}
				createdAt
				updatedAt
			}
		}
	}
`;
export const LIST_VESSEL_PORT_ADDRESS = gql`
	query searchByPortVesselAndAddress($query: String, $owner: String!) {
		searchByPortVesselAndAddress(query: $query, owner: $owner) {
			message
			data {
				uuid
				portName
				countryIso
				countryName
				unlocode
				portType
				geographic {
					type
					coordinates
				}
				areaLvl1
				areaLvl2
				state
				city
				municipality
				postcode
				type
			}
		}
	}
`;
export const LIST_ALL_VESSELS_DATALASTIC = gql`
	query trackVesselsHistoryPosition($id: String) {
		trackVesselsHistoryPosition(id: $id) {
			message
			data {
				isAvailable
				lat
				lon
				speed
				course
				heading
				destination
				last_position_epoch
				last_position_UTC
			}
		}
	}
`;
export const LIST_ALL_VESSELS_DETAILED_DATALASTIC = gql`
	query trackVesselsCurrentPosition($owner: String) {
		trackVesselsCurrentPosition(owner: $owner) {
			message
			data {
				isAvailable
				dl_type
				dl_subtype
				id: _id
				uuid
				name
				owner
				mmsi
				imo: imoNumber
				eni
				callSign
				specification {
					country
				}
				type
				type_specific
				dl_type
				dl_subtype
				lat
				lon
				speed
				course
				heading
				navigation_status
				destination
				last_position_epoch
				last_position_UTC
				eta_epoch
				eta_UTC
				current_draught
				dest_port_uuid
				dest_port
				dest_lat
				dest_lon
				dest_port_unlocode
				dep_port
				dep_port_unlocode
				atd_epoch
				atd_UTC
				timezone_offset_sec
				timezone
				dimension {
					overallLength
					depth
					length
					width
					grossTonnage
					netTonnage
					vesselCapacity
					dwt
				}
				attachments {
					_id
					path
					originalName
					mimetype
				}
				additionalInfo {
					has_excavator
					has_ramp
					has_side_door
					has_crane
					crane_total_tonnage
					vesselType
					cranes
					excavator
					refeerRooms
					refeerSlots
					forklift
					vesselCapacity
					sideDoor
				}
			}
		}
	}
`;

export const LIST_ALL_VESSELS_DETAILED_DATALASTIC_ADMIN = gql`
	query trackVesselsCurrentPosition($owner: String) {
		trackVesselsCurrentPosition(owner: $owner) {
			message
			data {
				isAvailable
				dl_type
				dl_subtype
				id: _id
				uuid
				name
				owner
				mmsi
				imo: imoNumber
				eni
				callSign
				specification {
					country
				}
				type
				type_specific
				dl_type
				dl_subtype
				lat
				lon
				speed
				course
				heading
				navigation_status
				destination
				last_position_epoch
				last_position_UTC
				eta_epoch
				eta_UTC
				current_draught
				dest_port_uuid
				dest_port
				dest_lat
				dest_lon
				dest_port_unlocode
				dep_port
				dep_port_unlocode
				atd_epoch
				atd_UTC
				timezone_offset_sec
				timezone
				dimension {
					overallLength
					depth
					length
					width
					grossTonnage
					netTonnage
					vesselCapacity
					dwt
				}
				attachments {
					_id
					path
					originalName
					mimetype
				}
				additionalInfo {
					has_excavator
					has_ramp
					has_side_door
					has_crane
					crane_total_tonnage
					vesselType
					cranes
					excavator
					refeerRooms
					refeerSlots
					forklift
					vesselCapacity
					sideDoor
				}
				ownership {
					_id
					email
					name
					tel
					web
				}
				isAdminVerified
				publicTags
				privateTags
			}
		}
	}
`;

export const GET_EACH_VESSEL = gql`
	query vessel($id: String!) {
		vessel(_id: $id) {
			message
			data {
				_id
				callSign
				imoNumber
				registerNumber
				name
				email
				status
				dimension {
					overallLength
					depth
					length
					width
					grossTonnage
					netTonnage
					vesselCapacity
					dwt
				}
				specification {
					vesselType
					constructionDate
					country
					jnr
					material
					propulsion
				}
				additionalInfo {
					has_excavator
					has_ramp
					has_side_door
					has_crane
					crane_total_tonnage
					vesselType
					cranes
					excavator
					refeerRooms
					refeerSlots
					forklift
					vesselCapacity
					fuelConsumption
					details
					sideDoor
					equippedWithCrane
					numberOfCranes
					craneDetail
					opex {
						currency
						amount
					}
				}
				attachments {
					_id
					mimetype
					path
					originalName
				}
				createdAt
				updatedAt
			}
		}
	}
`;

export const GET_VESSEL_TYPES = gql`
	query listVesselTypes {
		listVesselTypes {
			message
			data
		}
	}
`;
