/* eslint-disable react/no-unescaped-entities */
// @ts-nocheck
import { useState } from 'react';
import { Modal } from '@/components/modal';
import { Typography } from '@mui/material';
import { InlineTextButton } from '@/components/atomic/InlineTextButton';
import Button from '@/components/Button';

export const UndisclosedModalContent = ({ onClose }: any) => (
	<div className='flex flex-col px-8 pb-12 gap-6'>
		<div className='flex flex-col gap-2'>
			<Typography variant='h6'>Why can't I see this company name?</Typography>
			<Typography className='' variant='body2'>
				<p className='pb-4'>
					In order to keep quality standards high, Keelspot only reveals company name and contact
					information once an offer has been made.
				</p>
				<p>
					If you'd like to see company contact information now, please contact sales to learn more about
					Keelspot Plus.
				</p>
			</Typography>
		</div>
		<div className='flex flex-col items-center'>
			<div className='flex flex-col gap-1 justify-center items-center w-full max-w-[240px]'>
				<Button
					className='w-full'
					variant='contained'
					onClick={() => {
						window.open('https://www.keelspot.com/contact', '_blank');
					}}
				>
					Contact Us
				</Button>
				<Button className='w-full' variant='outlined' onClick={onClose}>
					Close
				</Button>
			</div>
		</div>
	</div>
);

export const UndisclosedExplanation = () => {
	const [showInfo, setShowInfo] = useState(false);
	return (
		<>
			<InlineTextButton className='text-xs no-underline' as='button' onClick={() => setShowInfo(true)}>
				See company name
			</InlineTextButton>
			<Modal title=' ' dialogAction={false} isOpen={showInfo} onClose={() => setShowInfo(false)}>
				<UndisclosedModalContent onClose={() => setShowInfo(false)} />
			</Modal>
		</>
	);
};
