import Badge from '@mui/material/Badge';
import { IconButton, Tooltip } from '@mui/material';
import { MdChat } from 'react-icons/md';

import { IconWrapper } from './Styled';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useEffect, useRef, useState } from 'react';
import { MessagePopover } from './MessagePopover';
import { useTalkSessionContext } from '@/context/TalkSessionContext';
import { Subscription } from 'talkjs/all';
import { useTranslation } from 'react-i18next';

export const MessageIcon = ({ currentComponent }: { currentComponent: HTMLDivElement | null }) => {
	const { t } = useTranslation();
	const talkSession = useTalkSessionContext();
	const anchorRef = useRef<HTMLButtonElement | null>(null);
	const { isOpen, onToggle, onClose } = useDisclosure();
	const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
	const unreadMessageOnChangeSubscription = useRef<Subscription>();
	function showBadge(notificationNumber: number) {
		return notificationNumber > 0;
	}
	useEffect(() => {
		if (talkSession) {
			unreadMessageOnChangeSubscription.current = talkSession.unreads.onChange(
				(unreadConversations) => {
					setUnreadMessagesCount(unreadConversations.length);
				},
			);
		}
		return () => {
			if (unreadMessageOnChangeSubscription.current) {
				unreadMessageOnChangeSubscription.current.unsubscribe();
			}
		};
	}, [talkSession]);
	return (
		<>
			<Tooltip title={t('mainNavbar.messages')} arrow>
				<IconButton onClick={onToggle} ref={anchorRef} sx={{ p: 0, borderRadius: 100 }}>
					<IconWrapper>
						<Badge
							badgeContent={
								showBadge(unreadMessagesCount) ? (
									<span id='notifier-badge'>{unreadMessagesCount}</span>
								) : null
							}
						>
							<MdChat size={24} />
						</Badge>
					</IconWrapper>
				</IconButton>
			</Tooltip>
			<MessagePopover anchorEl={currentComponent} onClose={onClose} open={isOpen} />
		</>
	);
};
