// eslint-disable-next-line
// @ts-nocheck
import { ToastContainer } from 'react-toastify';

import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClientProvider } from '@tanstack/react-query';

import AppRouter from '@/app/AppRouter';
import { ThemeProvider } from '@theme/Theme';
import { CONFIG, QUERY_CLIENT } from '@/config';
import { ThemeContextProvider } from '@context/ThemeContext';
import { GraphqlRequestContextProvider } from '@context/GraphqlRequestContext';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from '@sentry/react';

import './i18n';
import './index.css';
import { MapboxProvider } from './context/MapBoxContext';
import { TalkSessionProvider } from './context/TalkSessionContext';
import { MapDrawerProvider } from './context/MapDrawerContext';
import { MapStyleProvider } from './context/MapStyleContext';
import { SelectVesselProvider } from './context/SelectedVesselContext';
import { LicenseInfo } from '@mui/x-license-pro';
import { WizardsShownContextProvider } from './context/WizardsShownContext';
import { isInDemoMode } from './utils/isInDemoMode';

function FallbackComponent() {
	return <></>;
}

function App() {
	LicenseInfo.setLicenseKey(CONFIG.MUI_X_PRO_LICENSE_KEY);

	if (
		isInDemoMode &&
		window.location.href !== `${window.location.origin}/demo` &&
		(!localStorage.getItem('demoToken') ||
			localStorage.getItem('demoToken') < Date.now() - 1000 * 60 * 60 * 24)
	) {
		window.location.href = `${window.location.origin}/demo`;
		return;
	}

	// Initialization code that shouldn't run if we're redirecting

	const onRedirectCallback = (appState) => {
		if (appState?.returnTo) {
			localStorage.setItem('redirect', appState?.returnTo);
		}
	};

	return (
		<Sentry.ErrorBoundary fallback={FallbackComponent}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Auth0Provider
					domain={CONFIG.AUTH0_DOMAIN}
					clientId={CONFIG.AUTH0_CLIENT_ID}
					audience={CONFIG.AUTH0_AUDIENCE}
					redirectUri={`${window.location.origin}#KS`}
					useRefreshTokens={true}
					cacheLocation='localstorage'
					onRedirectCallback={onRedirectCallback}
				>
					<ToastContainer />
					<ThemeContextProvider>
						<ThemeProvider>
							<QueryClientProvider client={QUERY_CLIENT}>
								<ReactQueryDevtools initialIsOpen={false} position='bottom-left' />
								<GraphqlRequestContextProvider>
									<WizardsShownContextProvider>
										<TalkSessionProvider>
											<MapboxProvider>
												<MapDrawerProvider>
													<SelectVesselProvider>
														<MapStyleProvider>
															<Router>
																<AppRouter />
															</Router>
														</MapStyleProvider>
													</SelectVesselProvider>
												</MapDrawerProvider>
											</MapboxProvider>
										</TalkSessionProvider>
									</WizardsShownContextProvider>
								</GraphqlRequestContextProvider>
							</QueryClientProvider>
						</ThemeProvider>
					</ThemeContextProvider>
				</Auth0Provider>
			</LocalizationProvider>
		</Sentry.ErrorBoundary>
	);
}

export default Sentry.withProfiler(App);
