import { useQueries } from '@/graphql/useQueries';
import { useMutations } from '@/graphql/useMutations';
import {
	LIST_OWN_SHIPMENT,
	CREATE_SHIPMENT,
	GET_EACH_SHIPMENT,
	LIST_SHIPMENTS,
	DELETE_SHIPMENT,
} from '@/graphql/shipment';

export const useGetAllShipments = ({ status, query, owner }: any) => {
	return useQueries<any>({
		queryName: 'listShipments',
		gql: LIST_SHIPMENTS,
		args: { status, owner, query },
	});
};

export const useGetOwnShipments = <ResponseType = any>({ enabled, status, query }: any) =>
	useQueries<ResponseType>({
		queryName: 'listOwnShipments',
		gql: LIST_OWN_SHIPMENT,
		args: { status: status, query: query },
		config: {
			enabled: enabled,
		},
	});

export const useCreateShipment = (callback?: any) =>
	useMutations(['listOwnShipments'], CREATE_SHIPMENT, {
		onSuccess: () => {
			callback?.();
		},
	});

export const useGetShipmentDetail = (id?: string) =>
	useQueries<any>({
		queryName: 'shipment',
		gql: GET_EACH_SHIPMENT,
		args: { id: id },
		config: {
			enabled: !!id,
		},
	});

export const useDeleteShipment = (callback?: any) =>
	useMutations(['listOwnShipments'], DELETE_SHIPMENT, {
		onSuccess: () => {
			callback?.();
		},
	});
