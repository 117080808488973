import { useMemo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from '@/components/modal';
import DateUtils from '@/utils/DateUtils';
import { useSinceLastLogin } from '@/apiV2/sinceLastLogin';
import type { SinceLastLoginDataResponse, ShipmentType } from '@/apiV2/sinceLastLogin';
import { generateUrlPath } from '@/components/layouts/toolbar/NotificationPopover';
import { CompanyName } from '@/components/CompanyInfo';
import { notificationTitleTypeEnum, notificationTypeEnum } from '@/enums/NotificationEnum';
import Button from '@/components/Button';
import TextLabel from '@/components/atomic/TextLabel';
import { MdArrowForward, MdRemoveRedEye } from 'react-icons/md';
import useGetCurrentUser from '@/hooks/useGetCurrentUser';

const TextLabelStatus = ({
	type,
	notification,
	date,
}: {
	type: notificationTypeEnum;
	notification: notificationTitleTypeEnum;
	date: string;
}) => {
	if (type === notificationTypeEnum.PRIVATE_FREIGHT_REQUEST) {
		return (
			<div className='flex gap-2 items-center'>
				<TextLabel
					icon={<MdRemoveRedEye className='text-keelspot-primary-main' size={16} />}
					size='sm'
					color='black'
					text='Private freight request'
				/>
				<span className='text-xs'>{date}</span>
			</div>
		);
	}
	if (type === notificationTypeEnum.OFFER) {
		if (notificationTitleTypeEnum.OFFER_ACCEPTED === notification) {
			return (
				<div className='flex gap-2 items-center'>
					<TextLabel size='sm' color='successDark' text='Offer accepted!' />
					<span className='text-xs'>{date}</span>
				</div>
			);
		}
		if (notificationTitleTypeEnum.OFFER_REJECTED === notification) {
			return (
				<div className='flex gap-2 items-center'>
					<TextLabel size='sm' color='error' text='Offer rejected' />
					<span className='text-xs'>{date}</span>
				</div>
			);
		}
		return (
			<div className='flex gap-2 items-center'>
				<TextLabel size='sm' color='success' text='New offer!' />
				<span className='text-xs'>{date}</span>
			</div>
		);
	}
	if (type === notificationTypeEnum.COUNTER_OFFER) {
		if (notification === notificationTitleTypeEnum.COUNTEROFFER_ACCEPTED) {
			return (
				<div className='flex gap-2 items-center'>
					<TextLabel size='sm' color='successDark' text='Counter offer accepted!' />
					<span className='text-xs'>{date}</span>
				</div>
			);
		}
		return (
			<div className='flex gap-2 items-center'>
				<TextLabel size='sm' color='warning' text='Counter offer!' />
				<span className='text-xs'>{date}</span>
			</div>
		);
	}
	if (type === notificationTypeEnum.DELIVERY) {
		return (
			<div className='flex gap-2 items-center'>
				<TextLabel size='sm' color='primaryDark' text='Delivery' />
				<span className='text-xs'>{date}</span>
			</div>
		);
	}
	if (type === notificationTypeEnum.INTEREST) {
		return (
			<div className='flex gap-2 items-center'>
				<TextLabel size='sm' text='Interest expressed' />
				<span className='text-xs'>{date}</span>
			</div>
		);
	}
	return null;
};

const formatOfferMessage = ({
	notification,
	shipment,
}: {
	notification: notificationTitleTypeEnum;
	shipment: any;
}) => {
	const message =
		notification === notificationTitleTypeEnum.OFFER_ACCEPTED
			? 'accepted'
			: notification === notificationTitleTypeEnum.OFFER_REJECTED
			? 'rejected'
			: null;
	if (notification === notificationTitleTypeEnum.NEW_OFFER) {
		return (
			<span>
				Made a new offer to <b>{shipment?.name}</b>.
			</span>
		);
	} else {
		return (
			<span>
				Has <b>{message}</b> your offer for <b>{shipment?.name}</b>.
			</span>
		);
	}
};

const formatDeliveryMessage = (text: notificationTitleTypeEnum, shipment: ShipmentType) => {
	if (text === notificationTitleTypeEnum.PRIVATE_FREIGHT_REQUEST) {
		return (
			<span>
				Has <b>requested you to make an offer</b> on this freight request!
			</span>
		);
	}
	if (text === notificationTitleTypeEnum.DELIVERY_IN_PROGRESS) {
		return <span>{shipment?.name} is currently under delivery.</span>;
	} else if (text === notificationTitleTypeEnum.DELIVERY_UPDATE) {
		return (
			<span>
				<b>{shipment?.name}</b> has a new delivery update.
			</span>
		);
	} else if (text === notificationTitleTypeEnum.DELIVERY_FINISHED) {
		return (
			<span>
				<b>{shipment?.name}</b> has been delivered.
			</span>
		);
	} else if (text === notificationTitleTypeEnum.COUNTEROFFER_ACCEPTED) {
		return (
			<span>
				The counteroffer on <b>{shipment?.name}</b> has been accepted! Visit the deliveries page to put
				in the required details.
			</span>
		);
	} else if (text === notificationTitleTypeEnum.INTERESTED) {
		return <span>Expressed interest on your freight request!</span>;
	}

	// TODO: add counter offer text into this mess
	return (
		<span>
			There is a <b>new counter offer</b> on your negotiation.
		</span>
	);
};

export const SinceLastLogin = () => {
	const { currentUser } = useGetCurrentUser();
	const [delayedShowModal, setDelayedShowModal] = useState(false);
	const [closedModal, setClosedModal] = useState(false);
	const [showNumberOfNotifications, setShowNumberOfNotifications] = useState(3);
	const { response, loading } = useSinceLastLogin();
	const navigate = useNavigate();
	const url = useLocation();

	// Get the path of the url and check if its starts with /app/map
	const isMapPath = url.pathname.startsWith('/app/map');

	useEffect(() => {
		const timeout = setTimeout(() => {
			setDelayedShowModal(true);
		}, 2000);
		return () => {
			clearTimeout(timeout);
		};
	}, [setDelayedShowModal]);

	useEffect(() => {
		if (!isMapPath && delayedShowModal) {
			setClosedModal(true);
		}
	}, [isMapPath, delayedShowModal]);

	const unreadMessages = useMemo(() => {
		return (response?.data || []).slice(
			0,
			showNumberOfNotifications,
		) as unknown as SinceLastLoginDataResponse[];
	}, [response?.data, showNumberOfNotifications]);

	if (loading || unreadMessages.length === 0) {
		return null;
	}
	const now = new Date();
	const nowTimestamp = now.getTime();

	return (
		<Modal
			dialogWidth='550px'
			title=' '
			isOpen={!closedModal && delayedShowModal && unreadMessages?.length > 0}
			onClose={() => {
				setClosedModal(true);
			}}
			dialogAction={false}
		>
			<div className='w-full'>
				<div className='flex w-full flex-col px-4 mb-4'>
					<p className='text-center font-semibold'>Hi {currentUser?.name}! 👋</p>
					<h1 className='text-2xl font-semibold text-center'>This is new since your last login:</h1>
					<p className='text-center text-sm text-keelspot-text-secondary'>
						<strong className='text-keelspot-text-primary'>Tip:</strong> Quick replies builds trust and
						improve relationships!
					</p>
				</div>
				<div className='flex flex-col -gap-1 pb-8 w-full'>
					{unreadMessages?.map(
						({ _id, updatedAt, freightOwner, shipment, type, notification, relationId }) => {
							const timeago = new Date(updatedAt);
							return (
								<div
									className='w-full cursor-pointer -my-1 focus:bg-gray-100 hover:bg-gray-100 p-4 items-center flex gap-4 justify-between'
									role='button'
									onClick={() => {
										setClosedModal(true);
										navigate(generateUrlPath(type, relationId, notification));
									}}
									tabIndex={0}
									key={_id}
									onKeyDown={(e) => {
										if (e.key === 'Enter' || e.key === ' ') {
											setClosedModal(true);
											navigate(generateUrlPath(type, relationId, notification));
										}
									}}
								>
									<CompanyName
										label={
											<TextLabelStatus
												type={type}
												notification={notification}
												date={`${DateUtils.dateDaysAgo(nowTimestamp - timeago.getTime())} ago`}
											/>
										}
										contactName={
											type === notificationTypeEnum.OFFER
												? formatOfferMessage({
														shipment,
														notification,
												  })
												: formatDeliveryMessage(notification, shipment)
										}
										size='md'
										isBluechecked={!!freightOwner?.isBluecheck}
										undisclosed={false}
										companyName={freightOwner?.name}
									/>
									<MdArrowForward className='text-keelspot-text-secondary' size={24} />
								</div>
							);
						},
					)}
					{showNumberOfNotifications < (response?.data?.length || 0) ? (
						<div className='flex flex-col gap-2 items-center px-2'>
							<div className='h-[1px] w-full bg-keelspot-border-light my-2' />
							<Button
								onClick={() => {
									setShowNumberOfNotifications((prev) => prev + 10);
								}}
							>
								Show more
							</Button>
							<div className='flex items-center'>
								<span className='text-xs'>
									Showing{' '}
									<b>
										{showNumberOfNotifications} of {response?.data?.length}
									</b>{' '}
									notifications
								</span>
							</div>
						</div>
					) : (
						<div className='flex justify-center mt-6'>
							<Button onClick={() => setClosedModal(true)} variant='outlined'>
								Okay
							</Button>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};
