import { Badge, IconButton, Tooltip, useTheme } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { IconWrapper } from './Styled';
import { useRef, useState } from 'react';
import { useDisclosure } from '@/hooks/useDisclosure';
import { NotificationPopOver } from './NotificationPopover';
import { useTranslation } from 'react-i18next';

export const NotificationsButton = ({
	currentComponent,
}: {
	currentComponent: HTMLDivElement | null;
}) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const anchorRef = useRef<HTMLButtonElement | null>(null);
	const { isOpen, onToggle, onClose } = useDisclosure();
	const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
	function showBadge(notificationNumber: number) {
		return notificationNumber > 0;
	}
	function updateNumberOfNotifications(unreaded: number) {
		setUnreadMessagesCount(unreaded);
	}
	return (
		<>
			<Tooltip title={t('mainNavbar.alerts')} arrow>
				<IconButton onClick={onToggle} ref={anchorRef} sx={{ p: 0, borderRadius: 100 }}>
					<IconWrapper>
						<Badge
							badgeContent={
								showBadge(unreadMessagesCount) ? (
									<span id='notifier-badge-notification'>{unreadMessagesCount}</span>
								) : null
							}
						>
							<NotificationsIcon htmlColor={theme.palette.primary.contrastText} />
						</Badge>
					</IconWrapper>
				</IconButton>
			</Tooltip>
			<NotificationPopOver
				anchorEl={currentComponent}
				onClose={onClose}
				open={isOpen}
				setUnreadMessagesCount={updateNumberOfNotifications}
			/>
		</>
	);
};
