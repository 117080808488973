import { DELETE_NOTIFICATION, UPDATE_NOTIFICATION } from '@/graphql/notification/mutation';
import { NOTIFICATION_LIST } from '@/graphql/notification/query';
import { useMutations } from '@/graphql/useMutations';
import { useQueries } from '@/graphql/useQueries';
import { INotification } from '@/interfaces/notification/INotification';

export const useGetNotifications = (doCall: boolean) =>
	useQueries<INotification>({
		queryName: 'listNotifications',
		gql: NOTIFICATION_LIST,
		config: {
			enabled: doCall,
		},
	});
export const useUpdateNotification = (callback?: any) =>
	useMutations(['updateNotificationStatus'], UPDATE_NOTIFICATION, {
		onSuccess: () => {
			callback?.();
		},
	});
export const useDeleteNotification = (callback?: any) =>
	useMutations(['deleteNotification'], DELETE_NOTIFICATION, {
		onSuccess: () => {
			callback?.();
		},
	});
